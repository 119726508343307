import {faProductHunt} from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {IconProp} from "@fortawesome/fontawesome-svg-core";


export const SidebarFaIcon = (props: {icon: IconProp, selected: boolean}) => {
    return (
        <FontAwesomeIcon width={32} height={32} icon={props.icon} color={props.selected? 'white': 'gray'}></FontAwesomeIcon>
    )
}