import { TestStartAction } from './types';
import { testIsRunningSet } from './actions';
import { call, put, take, race, PutEffect, CallEffect, CancelEffect, ForkEffect, RaceEffect } from 'redux-saga/effects';

export function* testStartSaga(action: TestStartAction): Generator<CancelEffect | ForkEffect | CallEffect | PutEffect | RaceEffect<any>, void> {
  yield put(testIsRunningSet(true))
  const res = (yield race({
    stop: take('TEST_STOP'),
    timeout: new Promise(resolve => setTimeout(resolve, 3000))
  })) as any

  if (res.stop) {
    console.log('test stop')
  } else {
    console.log('test done')
  }

  yield put(testIsRunningSet(false))
}
