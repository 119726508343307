export const NAV_ACTIVE_TOOL_SET = 'NAV_ACTIVE_TOOL_SET';

export interface NavActiveToolSetAction {
  type: typeof NAV_ACTIVE_TOOL_SET;
  tool: string;
}



export type NavActionTypes =
    NavActiveToolSetAction
  ;