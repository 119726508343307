import "./SkuTool.less"

import {
  Alignment,
  AnchorButton,
  Button,
  ButtonGroup,
  Classes, Divider,
  FormGroup, Intent,
  Navbar, OverlayToaster,
  Popover,
  Switch, Toaster,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import "@blueprintjs/table/lib/css/table.css";
import {faStore} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {ReactNode, useEffect, useState} from "react";
import {Cell, Column, Table2} from "@blueprintjs/table";
import WorkspaceBar from "../WorkspaceBar";
import {uapConfigure} from "../../utils/Configure";
import {WebTable} from "webcommon";


const SkuTool = () => {
    const [tableButtonGroup, setTableButtonGroup] = useState<ReactNode | null>(null)
    return (
        <div className={"hotwords-tool-root"}>
            <WorkspaceBar
                title={'SKU管理'}
                tools={
                    () => {
                        return (
                            <div style={{display: "flex"}}>
                                {tableButtonGroup}
                                <ButtonGroup minimal={true}>
                                    <Button icon={IconNames.HELP}></Button>
                                </ButtonGroup>
                            </div>
                        )
                    }

                }></WorkspaceBar>
            <div>
                <WebTable
                    tableUrl={uapConfigure.urlBossApiSkuTablePrefix}
                    onTableButtonsLoad={setTableButtonGroup}
                ></WebTable>
            </div>
        </div>
    );
}

export default SkuTool;


