import { TEST_IS_RUNNING_SET, TestActionTypes } from './types';

export interface TestState {
  isRunning: boolean;
}


const testState: TestState = {
  isRunning: false
};

export const testReducer = (state = testState, action: TestActionTypes): TestState => {
  switch (action.type) {
    case TEST_IS_RUNNING_SET:
      return {
        ...state,
        isRunning: action.isRunning
      };
    default:
      return state;
  }
};

