export const TEST_IS_RUNNING_SET = 'TEST_IS_RUNNING_SET';

export const TEST_START = 'TEST_START';
export const TEST_STOP = 'TEST_STOP';

export interface TestIsRunningSetAction {
  type: typeof TEST_IS_RUNNING_SET;
  isRunning: boolean;
}

export interface TestStartAction {
  type: typeof TEST_START;
}

export interface TestStopAction {
  type: typeof TEST_STOP;
}



export type TestActionTypes =
  TestIsRunningSetAction |
  TestStartAction |
  TestStopAction
  ;