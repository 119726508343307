import React, {useContext} from 'react';
import './App.css';
import {Sidebar} from "./nav/Sidebar";
import {Workspace} from "./workspace/Workspace";
import {useSelector} from "react-redux";
import {AllState} from "./reduxes/all_reducers";


const App = () => {
    const navActivateTool = useSelector((state: AllState) => state.nav.navActivateTool);

    return (
        <div className="app bp5-light">
            <div className="main-layout">
                <Sidebar/>
                <Workspace uiActivatePanel={navActivateTool}/>
            </div>
        </div>

    );
}

export default App;
