import {
  TEST_IS_RUNNING_SET,
  TEST_START,
  TEST_STOP,
  TestIsRunningSetAction,
  TestStartAction,
  TestStopAction,
} from './types';

export const testIsRunningSet = (isRunning: boolean): TestIsRunningSetAction => ({
  type: TEST_IS_RUNNING_SET,
  isRunning
});



export const testStart = (): TestStartAction => ({
  type: TEST_START
});

export const testStop = (): TestStopAction => ({
  type: TEST_STOP
});


