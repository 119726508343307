// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

#root {
  width: 100%;
  height: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.navbar {
  height: 60px;
  background-color: #333;
  color: white;
  display: flex;
  align-items: center;
}

.main-layout {
  display: flex;
  flex: 1 1;
  overflow: hidden;
}

:focus {
  outline: none;
}

.bp5-button-group, input, button, select, textarea {
  -webkit-app-region: no-drag;
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,aAAa;AACf;;AAEA;EACE,YAAY;EACZ,sBAAsB;EACtB,YAAY;EACZ,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,SAAO;EACP,gBAAgB;AAClB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,2BAA2B;AAC7B","sourcesContent":["body {\r\n  margin: 0;\r\n  padding: 0;\r\n  overflow: hidden;\r\n}\r\n\r\n#root {\r\n  width: 100%;\r\n  height: 100%;\r\n}\r\n\r\n.app {\r\n  display: flex;\r\n  flex-direction: column;\r\n  height: 100vh;\r\n}\r\n\r\n.navbar {\r\n  height: 60px;\r\n  background-color: #333;\r\n  color: white;\r\n  display: flex;\r\n  align-items: center;\r\n}\r\n\r\n.main-layout {\r\n  display: flex;\r\n  flex: 1;\r\n  overflow: hidden;\r\n}\r\n\r\n:focus {\r\n  outline: none;\r\n}\r\n\r\n.bp5-button-group, input, button, select, textarea {\r\n  -webkit-app-region: no-drag;\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
