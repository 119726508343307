import { TEST_START } from './test/types';
import { testStartSaga } from './test/saga';
import { call, put, takeEvery, takeLatest, CallEffect, PutEffect, all } from 'redux-saga/effects';

function* navSagas() {

}

function* testSagas() {
  yield takeLatest(TEST_START, testStartSaga);
}


function* uapRootSagas() {
  yield all([testSagas(), navSagas()]);
}


export default uapRootSagas;