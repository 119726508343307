// import { legacy_createStore as createStore} from 'redux'
import createSagaMiddleware from 'redux-saga';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import uapRootSaga from './all_sagas';
import { testReducer } from './test/reducer';
import {navReducer} from "./nav/reducer";
import {allReducer} from "./all_reducers";

const sagaMiddleware = createSagaMiddleware();


// export const store = createStore(appReducer);
const store = configureStore({
  reducer: allReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(sagaMiddleware),
});

sagaMiddleware.run(uapRootSaga);

export default store;
