import React, { FC, ReactNode, Suspense, useEffect } from 'react';



import './Workspace.css'
import {useSelector} from "react-redux";
import HelloTool from "./hello_tool/HelloTool";
import ProductTool from "./product_tool/ProductTool";
import SkuTool from "./sku_tool/SkuTool";
import OrderTool from "./order_tool/OrderTool";

type ErrorBoundaryProps = {
  children: ReactNode;
};
const ErrorBoundary: FC<ErrorBoundaryProps> = ({ children }) => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      {children}
    </Suspense>
  );
};

const tools: { [key: string]: ReactNode } = {
  "product_tool": <ProductTool />,
  "sku_tool": <SkuTool />,
  "order_tool": <OrderTool />,
}

interface WorkspaceProps {
  uiActivatePanel: string;
}
export const Workspace  = (props: WorkspaceProps) => {

  const renderPanelContent = () => {
    if(tools.hasOwnProperty(props.uiActivatePanel)) {
      return tools[props.uiActivatePanel];
    } else {
      return <div>请选择工具</div>;
    }
  };

  return (
    <div className="workspace">
      {
        renderPanelContent()
      }
    </div>);
};

