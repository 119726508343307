import {NAV_ACTIVE_TOOL_SET, NavActionTypes} from './types';

export interface NavState {
  navActivateTool: string;
}


const navState: NavState = {
  navActivateTool: 'hello_tool'
};

export const navReducer = (state = navState, action: NavActionTypes): NavState => {
  switch (action.type) {
    case NAV_ACTIVE_TOOL_SET:
        return {
            ...state,
            navActivateTool: action.tool
        };
    default:
      return state;
  }
};

