import React, { useState } from 'react';
import {
  Collapse,
  Icon,
  IconName,
  Menu,
  MenuItem,
  Tag,
  Button,
  Card,
  AnchorButton,
  MenuDivider,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import './Sidebar.less'
import SidebarLogo from './SidebarLogo.svg';
import {
  faBook,
  faMemory,
  faRobot,
  faTv,
  faStore,
  faGlobe,
  faRadio,
  faGauge,
  faMap,
  faFileImport, faBoxesPacking, faUser, faUsers, faUsersCog, faMoneyBill, faCashRegister, faSms, faMailBulk, faKey
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useDispatch, useSelector} from "react-redux";
import {Dispatch} from "redux";
import {AllActionTypes} from "../reduxes/all_types";
import {AllState} from "../reduxes/all_reducers";
import {navActiveToolSet} from "../reduxes/nav/actions";
import {faGoogle, faProductHunt, faWeixin} from "@fortawesome/free-brands-svg-icons";
import {SidebarFaIcon} from "./SidebarFaIcon";

interface SidebarMenuItemData {
  key: string
  text: string;
  tag?: string;
  icon: (selected: boolean) => JSX.Element;
}

interface SidebarMenuItemProps {
  selected: boolean
  itemData: SidebarMenuItemData
  onClick: (itemData: SidebarMenuItemData) => void;
  isCollapsed: boolean;
}

const SidebarMenuItem = (props: SidebarMenuItemProps) => {
  return (
    <MenuItem className={`menu-item ${props.selected ? 'selected' : ''}`}
      icon={<Icon icon={props.itemData.icon(props.selected)} intent={props.selected ? 'primary' : 'none'} />}
      text={
        !props.isCollapsed && (
          <div>
            {props.itemData.text}
            {props.itemData.tag && <Tag intent="danger" round>{props.itemData.tag}</Tag>}
          </div>
        )
      }
      onClick={() => props.onClick(props.itemData)}
    />
  )
}



export const Sidebar = () => {
  const dispatch = useDispatch<Dispatch<AllActionTypes>>();

  const [isCollapsed, setIsCollapsed] = useState(false);
  const navActivateTool = useSelector((state: AllState) => state.nav.navActivateTool);
  const handleMenuItemClick = (itemData: SidebarMenuItemData) => {
    dispatch(navActiveToolSet(itemData.key))
  }

  const toggleSidebar = () => {
    setIsCollapsed(!isCollapsed);
  }


  const menuItems: (SidebarMenuItemData | 'divider')[] = [
    {
      key: 'product_tool',
      icon: (selected) => <SidebarFaIcon icon={faProductHunt} selected={selected}></SidebarFaIcon>,
      text: '产品管理'
    },
    {
      key: 'sku_tool',
      icon: (selected) => <SidebarFaIcon icon={faBoxesPacking} selected={selected}></SidebarFaIcon>,
      text: 'SKU管理'
    },
    {
      key: 'user_tool',
      icon: (selected) => <SidebarFaIcon icon={faUsersCog} selected={selected}></SidebarFaIcon>,
      text: '用户管理'
    },
    {
      key: 'order_tool',
      icon: (selected) => <SidebarFaIcon icon={faMoneyBill} selected={selected}></SidebarFaIcon>,
      text: '订单管理'
    }

  ]

  return (
    <Card className={`bp5-dark sidebar ${isCollapsed ? 'collapsed' : ''}`}>
      <AnchorButton
        icon={isCollapsed ? IconNames.MENU_OPEN : IconNames.MENU_CLOSED}
        minimal={true}
        onClick={toggleSidebar}
        className="sidebar-toggle"
      />
      <img className={`sidebar-logo  ${isCollapsed ? 'collapsed' : ''} `} src={SidebarLogo}></img>
      <h1 className={"sidebar-title"}>运营支撑系统</h1>
      {!isCollapsed && (
        <span className={'bp5-text-muted bp5-text-small'}>用户/支付/管理</span>
      )}


      <Menu className={`sidebar-menu ${isCollapsed ? 'collapsed' : ''}`}>
        {menuItems.map((data, index) =>
          data === 'divider' ? (
              <MenuDivider key={`divider-${index}`} />
            ) :
            (<SidebarMenuItem
              key={data.key}
              itemData={data}
              onClick={handleMenuItemClick}
              selected={data.key === navActivateTool}
              isCollapsed={isCollapsed}
            />))}
      </Menu>
    </Card>
  );
};