class UapConfigure {
    urlBossServer: string = 'https://boss-api.weerocks.com';

    urlBossApiProductAdd: string = `${this.urlBossServer}/boss/api/product/add`;
    urlBossApiProductUpdate: string = `${this.urlBossServer}/boss/api/product/update`;
    urlBossApiProductRemove: string = `${this.urlBossServer}/boss/api/product/remove`;
    urlBossApiProductList: string = `${this.urlBossServer}/boss/api/product/list`;

    urlBossApiSkuAdd: string = `${this.urlBossServer}/boss/api/sku/add`;
    urlBossApiSkuUpdate: string = `${this.urlBossServer}/boss/api/sku/update`;
    urlBossApiSkuRemove: string = `${this.urlBossServer}/boss/api/sku/remove`;
    urlBossApiSkuList: string = `${this.urlBossServer}/boss/api/sku/list`;
    urlBossApiProductTablePrefix = `${this.urlBossServer}/boss/api/product`;
    urlBossApiSkuTablePrefix = `${this.urlBossServer}/boss/api/sku`;
    urlBossApiOrderTablePrefix = `${this.urlBossServer}/boss/api/order`;
}

export const uapConfigure = new UapConfigure()