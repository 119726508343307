// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
 * Copyright 2023 Palantir Technologies, Inc. All rights reserved.
 */
.workspace-bar {
  -webkit-app-region: drag;
  -webkit-user-select: none;
  /* Safari */
  /* IE 10 及以上 */
  user-select: none;
  /* 标准语法 */
}
`, "",{"version":3,"sources":["webpack://./src/workspace/WorkspaceBar.less"],"names":[],"mappings":"AAAA;;EAEE;AAAF;EACI,wBAAA;EACA,yBAAA;EAEF,WAAW;EAEX,cAAc;EAFZ,iBAAA;EAIF,SAAS;AACX","sourcesContent":["@import \"@blueprintjs/core/lib/less/variables\";\n\n.workspace-bar {\n    -webkit-app-region: drag;\n    -webkit-user-select: none;  /* Safari */\n    -ms-user-select: none;      /* IE 10 及以上 */\n    user-select: none;          /* 标准语法 */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
