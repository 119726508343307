import { Alignment, AnchorButton, ButtonGroup, Navbar } from '@blueprintjs/core';
import React, { ReactNode } from 'react';
import './WorkspaceBar.less';
import { IconNames } from '@blueprintjs/icons';


interface WorkspaceBarProps {
  tools: () => ReactNode;
  title: string;
}
const WorkspaceBar = (props: WorkspaceBarProps) => {
  return (
    <Navbar className={'workspace-bar bp5-light'}>
      <Navbar.Group align={Alignment.LEFT}>
        <Navbar.Heading><strong>{props.title}</strong></Navbar.Heading>
        <Navbar.Divider />
        {props.tools()}
      </Navbar.Group>
    </Navbar>
  )
}

export default WorkspaceBar;