import "./OrderTool.less"

import {
    Alignment,
    AnchorButton,
    Button,
    ButtonGroup,
    Classes, Divider,
    FormGroup, Intent,
    Navbar, OverlayToaster,
    Popover, SegmentedControl,
    Switch, Toaster,
} from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import "@blueprintjs/table/lib/css/table.css";
import {faStore} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {ReactNode, useEffect, useState} from "react";
import {Cell, Column, Table2} from "@blueprintjs/table";
import WorkspaceBar from "../WorkspaceBar";

import {uapConfigure} from "../../utils/Configure";

import {WebTable} from "webcommon";


const OrderTool = () => {
    const [tableButtonGroup, setTableButtonGroup] = useState<ReactNode | null>(null)
    return (
        <div className={"hotwords-tool-root"}>
            <WorkspaceBar
                title={'订单管理'}
                tools={
                    () => {
                        return (
                            <div style={{display: "flex"}}>
                                {tableButtonGroup}
                                <ButtonGroup minimal={true}>
                                    <Button icon={IconNames.HELP}></Button>
                                </ButtonGroup>
                                <SegmentedControl
                                    options={[
                                        {
                                            label: "已支付",
                                            value: "list",
                                        },
                                        {
                                            label: "未支付",
                                            value: "grid",
                                        },
                                        {
                                            label: "全部",
                                            value: "gallery",
                                        },
                                    ]}
                                    defaultValue="list"
                                />
                            </div>
                        )
                    }

                }></WorkspaceBar>

            <div>
                <WebTable
                    tableUrl={uapConfigure.urlBossApiOrderTablePrefix}
                    onTableButtonsLoad={setTableButtonGroup}
                ></WebTable>
            </div>

        </div>
    );
}

export default OrderTool;


